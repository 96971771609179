<template>
  <div class="sprint_goals_row" v-if="nowSprintWeek">
    <!-- Day plan -->
    <b-row>
      <b-col>
        <h2 class="sprint_slider__title">План по дням</h2>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <div v-swiper:daySwiper="swiperOption">
          <div class="swiper-wrapper">
            <div
              v-for="(nowSprintWeekDay, index) in nowSprintWeek.days"
              :key="index"
              class="swiper-slide today"
            >
              <div class="sprint_card" :class="isToday(nowSprintWeekDay.date)">
                <b-row>
                  <b-col>
                    <h3 class="checklist_card__title">
                      {{ weekDayFormat(nowSprintWeekDay.date) }}
                    </h3>
                  </b-col>
                </b-row>

                <b-row align-h="center" class="checklist_wrapper">
                  <b-col cols="12">
                    <b-row
                      align-h="center"
                      align-v="center"
                      v-for="(goal, index) in nowSprintWeekDay.goals"
                      :key="index"
                    >
                      <b-col cols="auto" @click="changeGoalStatus(goal)">
                        <img
                          v-if="goal.goal_status_id == 1"
                          class="checklist_card__img"
                          src="../../assets/img/circle.svg"
                        />
                        <img
                          v-if="goal.goal_status_id == 2"
                          class="checklist_card__img"
                          src="../../assets/img/check-circle.svg"
                        />
                        <img
                          v-if="goal.goal_status_id == 3"
                          class="checklist_card__img"
                          src="../../assets/img/warning-circle.svg"
                        />
                      </b-col>

                      <b-col
                        class="checklist_card"
                        :class="goal.goal_status_id == 2 ? 'done' : ''"
                      >
                        <p class="checklist_card__text">
                          {{ goal.description }}
                        </p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>

              <!-- <b-row>
                <b-col>
                  <b-button :disabled="loading" block variant="primary" v-b-modal.planing>Редактировать</b-button>
                </b-col>
              </b-row> -->
            </div>
          </div>

          <div class="swiper-pagination"></div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
  name: "DayGoals",
  props: {
    statuses: Array,
    spheres: Array,
  },
  data() {
    return {
      swiperOption: {
        mousewheel: {
          forceToAxis: true,
        },
        clickable: false,
        // keyboard: {
        //   enabled: true,
        //   onlyInViewport: true,
        // },
        // Default parameters
        slidesPerView: 1,
        spaceBetween: 15,
        // Responsive breakpoints
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          // when window width is >= 640px
          1024: {
            slidesPerView: 3,
            spaceBetween: 15,
          },
        },

        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      },
    };
  },
  computed: {
    ...mapGetters(["loading", "nowSprintWeek"]),
  },
  methods: {
    ...mapActions(["getSprintWeeks"]),
    isToday(date) {
      return moment(new Date(date)).format("YYYY-MM-DD") ==
        moment(new Date()).format("YYYY-MM-DD")
        ? "today"
        : "";
    },
    formatDate(date) {
      moment.locale("ru");
      return moment(date).format("LL");
    },
    weekDayFormat(date) {
      moment.locale("ru");
      return moment(date).format("ddd LL");
    },

    async changeGoalStatus(goal) {
      try {
        this.$store.commit("SET_LOADING", true);

        await axios.post(`day_goal_status/${goal.id}`);

        await this.getSprintWeeks(this.$route.params.id);

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$store.commit("SET_LOADING", false);
        this.$bvToast.toast(`${e}`);
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss"></style>
