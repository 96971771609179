<template>
  <div>
    <!-- Sprint goals -->
    <b-row>
      <b-col>
        <h2 class="sprint_slider__title">Цели на спринт</h2>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <div v-swiper:sprintSwiper="swiperOption">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-b-modal.sprint_goal
              @click="setActiveSprintGoal(sprintGoal)"
              v-for="(sprintGoal, index) in allSprintGoals"
              :key="index"
            >
              <div class="sprint_card">
                <div
                  v-if="sprintGoal.img"
                  class="sprint_card__img_filter"
                ></div>

                <img
                  v-if="sprintGoal.img"
                  class="sprint_card__img"
                  :src="
                    $store.state.storageUrl +
                    '/app/sprint/sprint_goals/' +
                    sprintGoal.img
                  "
                />

                <b-row align-h="center">
                      <div
                      class="card-icon declared-icon"
                      v-if="sprintGoal.declared"
                    >
                      <img :src="require(`@/assets/img/SWM icons/outline/crown-1.svg`)" alt="">
                    </div>

                    <div
                      class="card-icon boosted-icon"
                      v-if="sprintGoal.boosted"
                    >
                      <p class="x_icon">10X</p>
                    </div>
                    </b-row>

                <b-row class="sprint_card__content" align-h="end">
                  <b-col cols="12">
                    <h3 v-if="sprintGoal.title" class="sprint_card__title">
                      {{ sprintGoal.title }}
                    </h3>
                    <p
                      v-if="sprintGoal.description"
                      class="sprint_card__description"
                    >
                      {{ sprintGoal.description }}
                    </p>
                  </b-col>

                  <b-col cols="auto" align-self="end">
                    <div
                      class="sprint_card__tag"
                      v-if="sprintGoal.goal_status"
                      :class="sprintGoal.goal_status.id == 2 ? 'success' : ''"
                    >
                      <p
                        v-if="sprintGoal.goal_status.title"
                        class="sprint_card__tag_text"
                      >
                        {{ sprintGoal.goal_status.title }}
                      </p>
                    </div>

                   
                  </b-col>
                </b-row>
                
              </div>
            </div>

            <div
              class="swiper-slide"
              v-b-modal.sprint_goal
              @click="setActiveSprintGoal()"
            >
              <div class="sprint_card">
                <div class="sprint_card__content">
                  <p class="sprint_card__add">+</p>
                </div>
              </div>
            </div>
          </div>

          <div class="swiper-pagination"></div>
        </div>
      </b-col>
    </b-row>

    <!-- Sprint Goal MODAL -->
    <b-modal
      id="sprint_goal"
      size="lg"
      hide-footer
      header-bg-variant="dark"
      body-bg-variant="dark"
      centered
    >
      <b-row align-h="center" v-if="sprintGoalEditor.img">
        <div class="global_goal__img_wrapper">
          <img
            class="global_goal__img"
            :src="
              $store.state.storageUrl +
              '/app/sprint/sprint_goals/' +
              sprintGoalEditor.img
            "
          />
        </div>
      </b-row>

      <b-row v-if="sprintGoalEditor.id" class="goals_delete">
        <b-col cols="12">
          <b-button
            v-if="sprintGoalEditor.alreadyDeclared != true"
            :disabled="loading"
            type="button"
            @click="deleteSprintGoal(sprintGoalEditor.id)"
            variant="outline-danger"
            size="sm"
            >Удалить</b-button
          >
        </b-col>
      </b-row>

      <!-- Info -->
      <b-row>
        <b-col cols="12">
          <div class="global_goal__new_img">
            <label>Картинка</label>
            <b-form-file
              v-model="sprintGoalEditor.newImg"
              :state="Boolean(sprintGoalEditor.newImg)"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
            ></b-form-file>
          </div>

          <b-form-checkbox
            v-model="sprintGoalEditor.declared"
            :value="1"
            :unchecked-value="0"
            :disabled="sprintGoalEditor.alreadyDeclared == true"
            switch
            class="gloval_goal__status"
          >
            <label>Задекларирована</label>
          </b-form-checkbox>

          <b-form-checkbox
            :disabled="sprintGoalEditor.alreadyDeclared == true"
            v-model="sprintGoalEditor.boosted"
            :value="1"
            :unchecked-value="0"
            switch
            class="gloval_goal__status"
          >
            <label>10X</label>
          </b-form-checkbox>

          <label>Статус</label>
          <b-form-select
            v-if="sprintGoalEditor.goal_status"
            class="gloval_goal__status"
            v-model="sprintGoalEditor.goal_status.id"
            :options="statuses"
          ></b-form-select>

          <label>Название</label>
          <b-form-input
            :disabled="sprintGoalEditor.alreadyDeclared == true"
            v-model="sprintGoalEditor.title"
            class="global_goal__title"
            placeholder="Название"
          ></b-form-input>

          <label>Описание</label>
          <b-form-input
            :disabled="sprintGoalEditor.alreadyDeclared == true"
            v-model="sprintGoalEditor.description"
            class="global_goal__description"
            placeholder="Описание"
          ></b-form-input>

          <div class="global_goal__deadline">
            <label for="sprintGoalDatepicker">Дедлайн</label>
            <b-form-datepicker
              id="sprintGoalDatepicker"
              :disabled="sprintGoalEditor.alreadyDeclared == true"
              v-model="sprintGoalEditor.deadline"
              hide-header
              aria-hidden=""
            ></b-form-datepicker>
          </div>
        </b-col>
      </b-row>

      <!-- Indicators -->
      <b-row>
        <b-col>
          <div class="indicators_wrapper">
            <p class="indicators_wrapper__title">Измеримые показатели</p>

            <div
              class="indicator"
              v-b-modal.indicator
              v-for="(indicator, index) in sprintGoalEditor.indicators"
              :key="index"
              @click="setActiveIndicator(indicator)"
            >
              <p class="indicator_title">{{ indicator.title }}</p>
              <b-progress
                :value="indicator.present_value"
                :max="indicator.final_value"
                show-progress
                animated
                variant="danger"
              ></b-progress>
            </div>

            <div
              class="indicator"
              v-b-modal.indicator
              @click="setActiveIndicator()"
            >
              <b-row align-h="center" align-v="center">
                <p class="indicator_add">+</p>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>

      <!-- Frontiers -->
      <b-row>
        <b-col>
          <h2 class="sprint_slider__title">Рубежи</h2>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <div v-swiper:frontiersSwiper="swiperOption">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-b-modal.frontier
                @click="setActiveFrontier(frontier)"
                v-for="(frontier, index) in sprintGoalEditor.frontiers"
                :key="index"
              >
                <div class="sprint_card">
                  <b-row class="sprint_card__content" align-h="end">
                    <b-col cols="12">
                      <p class="sprint_card__description">
                        {{ frontier.description }}
                      </p>

                      <p class="frontiers_deadline">
                        {{ weekDayFormat(frontier.deadline) }}
                      </p>

                      <p class="frontiers_reward">{{ frontier.reward }}</p>
                    </b-col>

                    <b-col v-if="frontier.status" cols="auto" align-self="end">
                      <div class="sprint_card__tag">
                        <p class="sprint_card__tag_text">
                          {{ frontier.status.title }}
                        </p>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>

              <div
                class="swiper-slide"
                v-b-modal.frontier
                @click="setActiveFrontier()"
              >
                <div class="sprint_card">
                  <div class="sprint_card__content">
                    <p class="sprint_card__add">+</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="swiper-pagination"></div>
          </div>
        </b-col>
      </b-row>

      <!-- Save button -->
      <b-row v-if="sprintGoalEditor.id">
        <b-button :disabled="loading" @click="saveSprintGoal()" block
          >Сохранить</b-button
        >
      </b-row>

      <b-row v-else>
        <b-button :disabled="loading" @click="addSprintGoal()" block
          >Создать</b-button
        >
      </b-row>
    </b-modal>

    <!-- Indicator MODAL -->
    <b-modal
      id="indicator"
      size="lg"
      hide-footer
      header-bg-variant="dark"
      body-bg-variant="dark"
      centered
    >
      <!-- Info -->
      <b-row>
        <b-col cols="12">
          <b-row v-if="indicatorEditor.id" class="goals_delete">
            <b-col cols="12">
              <b-button
                :disabled="loading"
                type="button"
                @click="deleteIndicator(indicatorEditor.id)"
                variant="outline-danger"
                size="sm"
                >удалить</b-button
              >
            </b-col>
          </b-row>

          <label>Описание</label>
          <b-form-input
            v-model="indicatorEditor.title"
            class="global_goal__title"
            placeholder="Описание"
          ></b-form-input>

          <label>Текущее значение</label>
          <b-form-input
            v-model="indicatorEditor.present_value"
            class="global_goal__title"
            placeholder="Текущее значение"
          ></b-form-input>

          <label>Цель</label>
          <b-form-input
            v-model="indicatorEditor.final_value"
            class="global_goal__description"
            placeholder="Цель"
          ></b-form-input>
        </b-col>
      </b-row>

      <!-- Save button -->
      <b-row v-if="indicatorEditor.id">
        <b-button :disabled="loading" block @click="saveIndicator()"
          >Сохранить</b-button
        >
      </b-row>

      <b-row v-else>
        <b-button :disabled="loading" block @click="addIndicator()"
          >Создать</b-button
        >
      </b-row>
    </b-modal>

    <!-- Frontier Edit MODAL -->
    <b-modal
      id="frontier"
      size="lg"
      hide-footer
      header-bg-variant="dark"
      body-bg-variant="dark"
      centered
    >
      <!-- Info -->
      <b-row>
        <b-col cols="12">
          <b-row v-if="frontierEditor.id" class="goals_delete">
            <b-col cols="12">
              <b-button
                :disabled="loading"
                type="button"
                @click="deleteFrontier(frontierEditor.id)"
                variant="outline-danger"
                size="sm"
                >удалить</b-button
              >
            </b-col>
          </b-row>

          <label v-if="frontierEditor.status">Статус</label>
          <b-form-select
            v-if="frontierEditor.status"
            class="gloval_goal__status"
            v-model="frontierEditor.status.id"
            :options="statuses"
          ></b-form-select>

          <label>Описание</label>
          <b-form-input
            v-model="frontierEditor.description"
            class="global_goal__title"
            placeholder="Описание"
          ></b-form-input>

          <div class="global_goal__deadline">
            <label for="frontierDatepicker">Дедлайн</label>
            <b-form-datepicker
              id="frontierDatepicker"
              v-model="frontierEditor.deadline"
              hide-header
              aria-hidden=""
            ></b-form-datepicker>
          </div>

          <label>Награда</label>
          <b-form-input
            v-model="frontierEditor.reward"
            class="global_goal__description"
            placeholder="Награда"
          ></b-form-input>

          <label>Наказание</label>
          <b-form-input
            v-model="frontierEditor.punishment"
            class="global_goal__description"
            placeholder="Наказание"
          ></b-form-input>
        </b-col>
      </b-row>

      <!-- Save button -->
      <b-row v-if="frontierEditor.id">
        <b-button @click="saveFrontier()" :disabled="loading" block
          >Сохранить</b-button
        >
      </b-row>

      <!-- Save button -->
      <b-row v-else>
        <b-button @click="addFrontier()" :disabled="loading" block
          >Создать</b-button
        >
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import lodash from "lodash";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
  name: "SprintGoals",
  props: {
    statuses: Array,
  },
  data() {
    return {
      swiperOption: {
        cssMode: true,
        mousewheel: {
          forceToAxis: true,
        },
        // Default parameters
        slidesPerView: 1,
        spaceBetween: 15,
        // Responsive breakpoints
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          // when window width is >= 640px
          1024: {
            slidesPerView: 3,
            spaceBetween: 15,
          },
        },

        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      },
      // Sprint goal
      sprintGoals: null,
      sprintGoalEditor: {
        id: null,
        title: null,
        description: null,
        img: null,
        newImg: null,
        deadline: null,
        done: null, // need delete in DB
        goal_status: {
          id: 1,
          title: null,
        },
        sprint_id: null,
      },
      // Indicator
      indicatorEditor: {
        id: null,
        title: null,
        present_value: null,
        final_value: null,
        sprint_goal_id: null,
        week_goal_id: null,
      },
      // Frontier
      frontierEditor: {
        id: 1,
        description: null,
        reward: null,
        punishment: null,
        deadline: null,
        user_id: null,
        sprint_goal_id: null,
        sprint_id: null,
        status: {
          id: 1,
          title: null,
        },
      },
    };
  },
  computed: {
    ...mapGetters(["allSprintGoals", "loading"]),
  },
  methods: {
    ...mapActions(["getSprintGoals"]),
    formatDate(date) {
      moment.locale("ru");
      return moment(date).format("LL");
    },
    weekDayFormat(date) {
      moment.locale("ru");
      return moment(date).format("ddd LL");
    },

    // Sprint goal
    setActiveSprintGoal(sprintGoal) {
      if (sprintGoal) {
        this.sprintGoalEditor = lodash.cloneDeep(sprintGoal);
      } else {
        this.sprintGoalEditor = {
          id: null,
          title: null,
          description: null,
          img: null,
          newImg: null,
          deadline: null,
          done: null, // need delete in DB
          goal_status: {
            id: 1,
            title: null,
          },
          sprint_id: null,
        };
      }

      if (sprintGoal.declared) {
        this.sprintGoalEditor.alreadyDeclared = true;
      }
    },
    async addSprintGoal() {
      let formData = new FormData();
      formData.append("title", this.sprintGoalEditor.title);
      formData.append("description", this.sprintGoalEditor.description);
      formData.append("done", this.sprintGoalEditor.done);
      formData.append("deadline", this.sprintGoalEditor.deadline);
      formData.append("goal_status_id", this.sprintGoalEditor.goal_status.id);
      formData.append("new_img", this.sprintGoalEditor.newImg);
      formData.append("sprint_id", this.$route.params.id);

      try {
        this.$store.commit("SET_LOADING", true);

        await axios.post(`add_sprint_goal/${this.$route.params.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        await this.getSprintGoals(this.$route.params.id);

        this.$bvModal.hide("sprint_goal");
        this.sprintGoalEditor = {
          id: null,
          title: null,
          description: null,
          img: null,
          newImg: null,
          deadline: null,
          done: null, // need delete in DB
          goal_status: {
            id: 1,
            title: null,
          },
          sprint_id: null,
        };

        this.$bvToast.toast("Цель на спринт успешно добавлена");

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$store.commit("SET_LOADING", false);
        this.$bvToast.toast(`${e}`);
        console.error(e);
      }
    },
    async saveSprintGoal() {
      let formData = new FormData();
      formData.append("title", this.sprintGoalEditor.title);
      formData.append("description", this.sprintGoalEditor.description);
      formData.append("done", this.sprintGoalEditor.done);
      formData.append("deadline", this.sprintGoalEditor.deadline);
      formData.append("goal_status_id", this.sprintGoalEditor.goal_status.id);
      formData.append("new_img", this.sprintGoalEditor.newImg);
      formData.append("sprint_id", this.$route.params.id);
      formData.append("boosted", this.sprintGoalEditor.boosted);

      if (this.sprintGoalEditor.alreadyDeclared) {
        formData.append("declared", true);
      } else {
        formData.append("declared", this.sprintGoalEditor.declared);
      }

      try {
        this.$store.commit("SET_LOADING", true);
        await axios.post(`sprint_goal/${this.sprintGoalEditor.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        await this.getSprintGoals(this.$route.params.id);

        this.$bvModal.hide("sprint_goal");
        this.sprintGoalEditor = {
          id: null,
          title: null,
          description: null,
          img: null,
          newImg: null,
          deadline: null,
          done: null, // need delete in DB
          goal_status: {
            id: 1,
            title: null,
          },
          sprint_id: null,
        };

        this.$bvToast.toast("Сохранено");

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$bvToast.toast(`${e}`);
        this.$store.commit("SET_LOADING", false);
        console.error(e);
      }
    },
    async deleteSprintGoal(sprintGoalId) {
      try {
        this.$store.commit("SET_LOADING", true);
        await axios.delete(`sprint_goal/${sprintGoalId}`);

        await this.getSprintGoals(this.$route.params.id);

        this.$bvModal.hide("sprint_goal");
        this.sprintGoalEditor = {
          id: null,
          title: null,
          description: null,
          img: null,
          newImg: null,
          deadline: null,
          done: null, // need delete in DB
          goal_status: {
            id: 1,
            title: null,
          },
          sprint_id: null,
        };

        this.$bvToast.toast("Цель на спринт удалена");

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$bvToast.toast(`${e}`);
        this.$store.commit("SET_LOADING", false);
        console.error(e);
      }
    },
    // Indicator
    setActiveIndicator(indicator) {
      if (indicator) {
        this.indicatorEditor = lodash.cloneDeep(indicator);
      } else {
        this.indicatorEditor = {
          id: null,
          title: null,
          present_value: null,
          final_value: null,
          sprint_goal_id: null,
          week_goal_id: null,
        };
      }

      if (this.sprintGoalEditor.indicators) {
        this.indicatorEditor.sprint_goal_id = this.sprintGoalEditor.id;
      } else if (this.weekGoalEditor.indicators) {
        this.indicatorEditor.week_goal_id = this.weekGoalEditor.id;
      }
    },
    async addIndicator() {
      let formData = new FormData();
      formData.append("title", this.indicatorEditor.title);
      formData.append("present_value", this.indicatorEditor.present_value);
      formData.append("final_value", this.indicatorEditor.final_value);
      formData.append("sprint_goal_id", this.indicatorEditor.sprint_goal_id);
      formData.append("week_goal_id", this.indicatorEditor.week_goal_id);

      try {
        this.$store.commit("SET_LOADING", true);

        await axios.post("indicators", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        await this.getSprintGoals(this.$route.params.id);

        this.$bvModal.hide("indicator");
        this.$bvModal.hide("sprint_goal"); //need upgrade
        this.indicatorEditor = {
          id: null,
          title: null,
          present_value: null,
          final_value: null,
          sprint_goal_id: null,
          week_goal_id: null,
        };

        this.$bvToast.toast("Показатель успешно добавлен");

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$store.commit("SET_LOADING", false);
        this.$bvToast.toast(`${e}`);
        console.error(e);
      }
    },
    async saveIndicator() {
      let formData = new FormData();
      formData.append("title", this.indicatorEditor.title);
      formData.append("present_value", this.indicatorEditor.present_value);
      formData.append("final_value", this.indicatorEditor.final_value);
      formData.append("sprint_goal_id", this.indicatorEditor.sprint_goal_id);
      formData.append("week_goal_id", this.indicatorEditor.week_goal_id);

      try {
        this.$store.commit("SET_LOADING", true);
        await axios.post(`indicators/${this.indicatorEditor.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        await this.getSprintGoals(this.$route.params.id);

        this.$bvModal.hide("indicator");
        this.$bvModal.hide("sprint_goal"); //need upgrade
        this.indicatorEditor = {
          id: null,
          title: null,
          present_value: null,
          final_value: null,
          sprint_goal_id: null,
          week_goal_id: null,
        };

        this.$bvToast.toast("Сохранено");

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$bvToast.toast(`${e}`);
        this.$store.commit("SET_LOADING", false);
        console.error(e);
      }
    },
    async deleteIndicator(indicatorId) {
      try {
        this.$store.commit("SET_LOADING", true);
        await axios.delete(`indicators/${indicatorId}`);

        await this.getSprintGoals(this.$route.params.id);

        this.$bvModal.hide("indicator");
        this.$bvModal.hide("sprint_goal"); //need upgrade

        this.indicatorEditor = {
          id: null,
          title: null,
          present_value: null,
          final_value: null,
          sprint_goal_id: null,
          week_goal_id: null,
        };

        this.$bvToast.toast("Показатель удален");

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$bvToast.toast(`${e}`);
        this.$store.commit("SET_LOADING", false);
        console.error(e);
      }
    },
    // Frontiers
    setActiveFrontier(frontier) {
      if (frontier) {
        this.frontierEditor = lodash.cloneDeep(frontier);
      } else {
        this.frontierEditor = {
          id: null,
          title: null,
          present_value: null,
          final_value: null,
          sprint_goal_id: null,
          week_goal_id: null,
          status: {
            id: 1,
            title: null,
          },
        };
      }
    },
    async addFrontier() {
      let formData = new FormData();
      formData.append("description", this.frontierEditor.description);
      formData.append("reward", this.frontierEditor.reward);
      formData.append("punishment", this.frontierEditor.punishment);
      formData.append("deadline", this.frontierEditor.deadline);
      formData.append("sprint_goal_id", this.sprintGoalEditor.id);
      formData.append("goal_status_id", this.frontierEditor.status.id);
      formData.append("sprint_id", this.$route.params.id);

      try {
        this.$store.commit("SET_LOADING", true);

        await axios.post("frontiers", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        await this.getSprintGoals(this.$route.params.id);

        this.$bvModal.hide("frontier");
        this.$bvModal.hide("sprint_goal"); //need upgrade
        this.frontierEditor = {
          id: 1,
          description: null,
          reward: null,
          punishment: null,
          deadline: null,
          user_id: null,
          sprint_goal_id: null,
          sprint_id: null,
          status: {
            id: 1,
            title: null,
          },
        };

        this.$bvToast.toast("Рубеж успешно добавлен");

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$store.commit("SET_LOADING", false);
        this.$bvToast.toast(`${e}`);
        console.error(e);
      }
    },
    async saveFrontier() {
      let formData = new FormData();
      formData.append("description", this.frontierEditor.description);
      formData.append("reward", this.frontierEditor.reward);
      formData.append("punishment", this.frontierEditor.punishment);
      formData.append("deadline", this.frontierEditor.deadline);
      formData.append("sprint_goal_id", this.frontierEditor.sprint_goal_id);
      formData.append("goal_status_id", this.frontierEditor.status.id);

      try {
        this.$store.commit("SET_LOADING", true);
        await axios.post(`frontiers/${this.frontierEditor.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        await this.getSprintGoals(this.$route.params.id);

        this.$bvModal.hide("frontier");
        this.$bvModal.hide("sprint_goal"); //need upgrade
        this.frontierEditor = {
          id: 1,
          description: null,
          reward: null,
          punishment: null,
          deadline: null,
          user_id: null,
          sprint_goal_id: null,
          sprint_id: null,
          status: {
            id: 1,
            title: null,
          },
        };

        this.$bvToast.toast("Сохранено");

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$bvToast.toast(`${e}`);
        this.$store.commit("SET_LOADING", false);
        console.error(e);
      }
    },
    async deleteFrontier(frontierId) {
      try {
        this.$store.commit("SET_LOADING", true);
        await axios.delete(`frontiers/${frontierId}`);

        await this.getSprintGoals(this.$route.params.id);

        this.$bvModal.hide("frontier");
        this.$bvModal.hide("sprint_goal"); //need upgrade
        this.frontierEditor = {
          id: 1,
          description: null,
          reward: null,
          punishment: null,
          deadline: null,
          user_id: null,
          sprint_goal_id: null,
          sprint_id: null,
          status: {
            id: 1,
            title: null,
          },
        };

        this.$bvToast.toast("Рубеж удален");

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$bvToast.toast(`${e}`);
        this.$store.commit("SET_LOADING", false);
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss">
.x_icon {
  font-weight: 800;
}
</style>
