<template>
  <div>
    <div class="padding_top"></div>

    <b-container>
      <b-row v-if="sprint" class="sprint_block">
        <b-col cols="12" md="6">
          <div class="sprint_menu" @click="editSprint()">
            <svg
              class="sprint_icon_wrap"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                v-if="!editing"
                class="sprint_icon"
                d="M4 16L3.46967 15.4697C3.32902 15.6103 3.25 15.8011 3.25 16H4ZM17 3L17.5303 2.46967C17.2374 2.17678 16.7626 2.17678 16.4697 2.46967L17 3ZM21 7L21.5303 7.53033C21.8232 7.23744 21.8232 6.76256 21.5303 6.46967L21 7ZM8 20V20.75C8.19891 20.75 8.38968 20.671 8.53033 20.5303L8 20ZM4 20H3.25C3.25 20.4142 3.58579 20.75 4 20.75V20ZM12 19.25C11.5858 19.25 11.25 19.5858 11.25 20C11.25 20.4142 11.5858 20.75 12 20.75V19.25ZM20.5 20.75C20.9142 20.75 21.25 20.4142 21.25 20C21.25 19.5858 20.9142 19.25 20.5 19.25V20.75ZM4.53033 16.5303L17.5303 3.53033L16.4697 2.46967L3.46967 15.4697L4.53033 16.5303ZM16.4697 3.53033L20.4697 7.53033L21.5303 6.46967L17.5303 2.46967L16.4697 3.53033ZM20.4697 6.46967L7.46967 19.4697L8.53033 20.5303L21.5303 7.53033L20.4697 6.46967ZM8 19.25H4V20.75H8V19.25ZM4.75 20V16H3.25V20H4.75ZM13.4697 6.53033L17.4697 10.5303L18.5303 9.46967L14.5303 5.46967L13.4697 6.53033ZM12 20.75H20.5V19.25H12V20.75Z"
              />

              <path
                v-else
                class="sprint_icon"
                d="M15 20V15H9V20M20 9.82843V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V6C4 4.89543 4.89543 4 6 4H14.1716C14.702 4 15.2107 4.21071 15.5858 4.58579L19.4142 8.41421C19.7893 8.78929 20 9.29799 20 9.82843Z"
                stroke="#001A72"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <p class="icon_desc">
              {{ editing == true ? "Сохранить" : "Изменить" }}
            </p>
          </div>

          <p class="sprint_title" v-if="!editing && sprint.title">
            <span class="sprint_subtitle">Цель спринта:</span><br />
            {{ sprint.title }}
          </p>

          <div class="sprint__date_block" v-if="!editing">
            <p class="sprint__date" v-if="sprint.start && sprint.end">
              <span class="sprint_subtitle">Период:</span><br />{{
                formatDate(sprint.start)
              }}
              - {{ formatDate(sprint.end) }}
            </p>

            <p class="sprint__date" v-else>
              <span class="sprint_subtitle">❗️Добавьте период спринта</span>
            </p>
          </div>

          <b-row v-else>
            <b-col cols="12" md="10">
              <label for="">Цель спринта</label>
              <b-form-input
                type="text"
                v-model="editingSprint.title"
              ></b-form-input>

              <label for="">Старт</label>
              <b-datepicker
                type="text"
                v-model="editingSprint.start"
                locale="ru"
                :start-weekday="1"
              ></b-datepicker>

              <label for="">Окончание</label>
              <b-datepicker
                type="text"
                v-model="editingSprint.end"
                locale="ru"
                :start-weekday="1"
              ></b-datepicker>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- <day-goals /> -->

      <sprint-goals :statuses="statuses" />

      <sprint-weeks :statuses="statuses" />
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import SprintWeeks from "../../components/sprint/SprintWeeks.vue";
import GlobalGoal from "../../components/sprint/GlobalGoal.vue";
import SprintGoals from "../../components/sprint/SprintGoals.vue";
import DayGoals from "../../components/sprint/DayGoals.vue";
import moment from "moment";
import lodash from "lodash";

export default {
  components: { SprintWeeks, GlobalGoal, SprintGoals, DayGoals },
  name: "Sprint",
  data() {
    return {
      // Statuses
      globalGoalStatus: 1,

      // Using variables
      statuses: [
        { value: 1, text: "в работе" },
        { value: 2, text: "выполнена" },
        { value: 3, text: "просрочена" },
      ],
      spheres: [],

      sprint: null,
      editingSprint: {
        id: null,
        title: null,
        start: null,
        end: null,
      },
      editing: false,

      swiperOption: {
        cssMode: true,
        mousewheel: {
          forceToAxis: true,
        },
        // Default parameters
        slidesPerView: 1,
        spaceBetween: 15,
        // Responsive breakpoints
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          // when window width is >= 640px
          1024: {
            slidesPerView: 3,
            spaceBetween: 15,
          },
        },

        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      },
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    ...mapActions(["getSprintWeeks", "getGlobalGoals", "getSprintGoals"]),
    formatDate(date) {
      return moment(date).format("DD MMMM YYYY");
    },
    async editSprint() {
      this.editing = !this.editing;

      if (this.editing == true) {
        this.editingSprint = lodash.cloneDeep(this.sprint);
      } else if (this.editing == false) {
        try {
          this.$store.commit("SET_LOADING", true);

          await axios.patch(
            `sprint/${this.editingSprint.id}`,
            this.editingSprint
          );

          await this.getSprint();

          this.$store.commit("SET_LOADING", false);
        } catch (e) {
          this.$store.commit("SET_LOADING", false);
          this.$bvToast.toast(`${e}`);
          console.error(e);
        }
      }
    },
    async getSprint() {
      this.sprint = await axios
        .get(`sprint/${this.$route.params.id}`)
        .then((res) => {
          return res.data;
        });

      await this.getSprintGoals(this.$route.params.id);

      await this.getSprintWeeks(this.$route.params.id);
    },
  },
  async mounted() {
    if (!localStorage.getItem("token")) {
      return;
    }

    try {
      this.$store.commit("SET_LOADING", true);

      await this.$store.dispatch("getUser");

      let lifeSpheres = await axios.get("life_spheres").then((res) => {
        return res.data;
      });
      this.spheres = lifeSpheres;

      await this.getGlobalGoals(this.$route.params.id);

      await this.getSprint();

      this.$store.commit("SET_LOADING", false);
    } catch (e) {
      this.$store.commit("SET_LOADING", false);
      this.$bvToast.toast(`${e}`);
      console.error(e);
    }
  },
};
</script>

<style lang="scss">
.sprint_block {
  padding-bottom: 5vh;
}
.sprint_subtitle {
  font-size: 16px;
  text-transform: none;
  color: rgba($color: $white, $alpha: 0.8);
}

.sprint__date_block {
  display: flex;
}
.sprint__date {
  padding-right: 5px;
  font-weight: 400;
  font-size: 18px;
}
.sprint_title {
  font-size: 22px;
  font-weight: 500;
  text-transform: uppercase;

  margin-bottom: 10px;
}

.sprint_menu {
  display: flex;
  margin-bottom: 30px;
  cursor: pointer;
}
.sprint_icon {
  fill: rgba($color: $white, $alpha: 0.8);
  transition: all 400ms;
}
.sprint_menu:hover {
  .sprint_icon {
    fill: rgba($color: $white, $alpha: 1);

    transition: all 200ms;
  }
  .icon_desc {
    color: rgba($color: $white, $alpha: 1);
    transition: all 200ms;
  }
}
.icon_desc {
  padding-left: 10px;
  color: rgba($color: $white, $alpha: 0.8);
  transition: all 400ms;
}
</style>
